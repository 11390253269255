<template>
  <div :style="`font-family:${font},sans-serif`">
    <!-- <div>
      <p>{{ this.$route.params.id.check_code }}</p>
    </div> -->
    <!-- {{ this.rows }} -->
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12">
        <div>
          <div class="row justify-content-between">
            <div class="col-4 mt-1" style="text-align: start">
              <b-button variant="gradient-secondary" class="mr-1" @click="$router.push({ name: 'selling' })"
                >กลับ</b-button
              >
              <b-button variant="gradient-secondary" class="mr-1" @click="exportpdf"
                >พิมพ์ทะเบียนรายการจำหน่าย</b-button
              >
            </div>

            <div class="col-4 mt-1" style="text-align: center">
              <div class="title head">
                <p class="h2" style="color: #558cef">รายการจำหน่ายจากทะเบียนทรัพย์สิน</p>
              </div>
            </div>
            <div class="col-4" style="text-align: end">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <b-button variant="outline-primary"
                    ><feather-icon icon="ShareIcon" class="mr-50" />Export
                    <feather-icon icon="ChevronDownIcon" class="mr-50"
                  /></b-button>
                </template>
                <b-dropdown-item @click="exportExcel">
                  <feather-icon icon="FileIcon" class="mr-50" />
                  <span>Excel</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>

          <hr />

          <!-- search input -->
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">รหัส :</div>
            <div class="ml-1 h4">{{ this.$route.params.id.check_code }}</div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">ปีงบประมาณ :</div>
            <div class="ml-1 h4">{{ this.$route.params.id.budget_year }}</div>
            <div class="ml-1 h4 text-primary" text="primary">วันที่ :</div>
            <div class="ml-1 h4">
              {{ this.$route.params.id.action_date_th }}
            </div>
          </div>

          <hr />

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :line-numbers="true"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->
              <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                <b-avatar :src="props.row.avatar" class="mx-1" />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '100']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap">of {{ total }} entries</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { BFormFile } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import { BFormDatepicker } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import pdfMake from "pdfmake";
import pdfFonts from "../font/custom-fonts";
import _ from "lodash";
export default {
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
  },
  data() {
    return {
      pageLength: 100,
      dir: false,
      total: "",
      columns: [
        {
          label: "ปีงบประมาณ",
          field: "budget_year",
        },
        {
          label: "เลขทะเบียนทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "SERIAL NUMBER",
          field: "serial_number",
        },
        {
          label: "ราคา",
          field: "unitprice",
          formatFn: this.formatFn,
        },

        {
          label: "รายการ",
          field: "e_name",
        },
        {
          label: "สถานที่เก็บ",
          field: "place",
        },
        {
          label: "การจำหน่าย",
          field: "reject_text",
        },
        {
          label: "ราคาจำหน่าย",
          field: "price",
          type: "number",
          formatFn: this.formatFn,
        },
      ],
      rows: [],
      searchTerm: "",
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      font: "",
    };
  },
  created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    _.isEmpty(this.$route.params.id.check_code) ? this.$router.push({ path: "/selling" }) : true;

    this.get_data_table();
  },
  methods: {
    formatFn: function (value) {
      return `${value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : " "}`;
    },
    exportpdf() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Medium.ttf",
          bold: "Sarabun-Regular.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [10, 60, 10, 50],
        pageSize: "A4",
        pageOrientation: "landscape",
        info: {
          title: "ทะเบียนการจำหน่าย",
        },
        header: [
          { text: "ทะเบียนการจำหน่าย", style: "header", margin: [0, 10, 0, 0] },
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            style: "header",
          },
          {
            text: `ณ วันที่ ${parseInt(this.$route.params.id.action_date.split("-")[2])} ${
              this.month[parseInt(this.$route.params.id.action_date.split("-")[1])]
            } ${Number(this.$route.params.id.action_date.split("-")[0]) + 543}`,
            style: "header",
          },
        ],
        content: [
          {
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "*", "auto", "auto", "*", "auto", "auto", "auto", "auto"],
              body: [
                [
                  { text: "ลำดับ", style: "tableHeader" },
                  { text: "ปีงบประมาณ", style: "tableHeader" },
                  {
                    text: "เลขทะเบียนทรัพย์สิน",
                    style: "tableHeader",
                    width: "50",
                  },
                  { text: "SERIAL NUMBER", style: "tableHeader" },
                  { text: "ราคา", style: "tableHeader" },
                  { text: "รายการ", style: "tableHeader" },
                  { text: "สถานที่เก็บ", style: "tableHeader" },
                  { text: "การจำหน่าย", style: "tableHeader" },
                  { text: "ราคาจำหน่าย", style: "tableHeader" },
                  { text: "หมายเหตุ", style: "tableHeader" },
                ],
                ...this?.rows.map((res, index) => {
                  return [
                    { text: index + 1, style: "text_center" },
                    { text: res.budget_year, style: "text_center" },
                    { text: res.e_number, style: "text_center" },
                    { text: res.serial_number, style: "text_center" },
                    {
                      text: `${
                        res.unitprice ? res.unitprice.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : " "
                      }`,
                      style: "text_right",
                    },
                    { text: res.e_name, style: "text_center" },
                    { text: res.place, style: "text_center" },
                    { text: res.reject_text, style: "text_center" },
                    {
                      text: `${res.price ? res.price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : " "}`,
                      style: "text_right",
                    },
                    { text: "", style: "text_center" },
                  ];
                }),
              ],
            },
          },
        ],
        defaultStyle: {
          font: "Kanit",
        },
        styles: {
          tableExample: {
            margin: [0, 5, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 9,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 15, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            fontSize: 9,
          },
          text_right: {
            alignment: "right",
            fontSize: 9,
          },
          text: {
            fontSize: 9,
          },
        },
      };
      pdfMake.createPdf(docDefinition).download("รายการจำหน่ายจากทะเบียนทรัพย์สิน");
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "รายการจำหน่ายจากทะเบียนทรัพย์สิน",
        columns: [
          {
            title: "รายการจำหน่ายจากทะเบียนทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "ปีงบประมาณ",
                field: "budget_year",
              },
              {
                title: "เลขทะเบียนทรัพย์สิน",
                field: "e_number",
              },
              {
                title: "SERIAL NUMBER",
                field: "serial_number",
              },
              {
                title: "ราคา",
                field: "unitprice_ex",
              },

              {
                title: "รายการ",
                field: "e_name",
              },
              {
                title: "สถานที่เก็บ",
                field: "place",
              },
              {
                title: "การจำหน่าย",
                field: "reject_text",
              },
              {
                title: "ราคาจำหน่าย",
                field: "price_ex",
              },
            ],
          },
        ],
      });
    },
    async get_data_table() {
      const { access_token } = await this.access_token();
      const url = `${API}disposeOfHistoryList?_limit=100&check_code=${this.$route.params.id.check_code}`;
      console.log(url);
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message.data);

      this.rows = res.data.message.data.map((el) => {
        return {
          ...el,
          unitprice_ex: `${
            el.unitprice
              ? Number(el.unitprice)
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : " "
          }`,
          price_ex: `${
            el.price
              ? Number(el.price)
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : " "
          }`,
        };
      });
      this.total = res.data.message.total;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
